import _ from "lodash";
import { Navigate, RouteObject } from "react-router-dom";

import { Activity } from "./activity/screens/activity";
import { activityLoader } from "./activity/screens/activity/activity-loader";
import { Report, reportLoader } from "./activity/screens/report";
import { RequireAuth } from "./auth";
import { ErrorBoundary } from "./common/components/error-boundary";
import { Page } from "./common/components/page";
import { CONFIG } from "./config";
import { AppList } from "./dashboard/components/app-list";
import { Page as DashboardPage } from "./dashboard/components/page";
import {
  SpawnerGroups,
  spawnerGroupsAction,
  spawnerGroupsLoader,
} from "./dashboard/components/spawner-groups";
import { User, userAction, userLoader } from "./dashboard/components/user";
import { Users, usersAction, usersLoader } from "./dashboard/components/users";
import {
  Workspace,
  workspaceAction,
  workspaceLoader,
} from "./dashboard/components/workspace";
import {
  Workspaces,
  workspacesAction,
  workspacesLoader,
} from "./dashboard/components/workspaces";
import { Admin } from "./dashboard/screens/admin";
import { ApiMode, TabComponentMode } from "./dashboard/types";
import paths from "./paths";
import { Faq } from "./resources/screens/faq";
import { Auth } from "./user/screens/auth";
import { Settings } from "./user/screens/settings";
import { Tokens } from "./user/screens/tokens";

export const routes: RouteObject[] = [
  {
    path: paths.ACTIVITY_REPORT_PDF,
    element: <RequireAuth />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <Report />,
        loader: reportLoader,
      },
    ],
  },
  {
    element: <Page />,
    path: "/",
    errorElement: (
      <Page>
        <ErrorBoundary />
      </Page>
    ),
    children: [
      {
        path: paths.SIGN_IN,
        element: (
          <Auth defaultAuthedPage={paths.DASHBOARD} initialState="signIn" />
        ),
      },
      {
        path: paths.SIGN_UP,
        element: (
          <Auth defaultAuthedPage={paths.DASHBOARD} initialState="signUp" />
        ),
      },
      {
        path: paths.FAQ,
        element: <Faq />,
      },
      {
        path: paths.TOKENS,
        element: (
          <RequireAuth>
            <Tokens />
          </RequireAuth>
        ),
      },
      {
        path: paths.SETTINGS,
        element: (
          <RequireAuth>
            <Settings />
          </RequireAuth>
        ),
      },
      {
        path: paths.DASHBOARD,
        element: (
          <RequireAuth>
            <DashboardPage />
          </RequireAuth>
        ),
        children: _.compact([
          {
            path: paths.DASHBOARD,
            element: (
              <RequireAuth role={_.get(CONFIG.TAB_ROLES, "Home")}>
                <AppList />
              </RequireAuth>
            ),
          },
          {
            path: paths.DASHBOARD_ACTIVITY,
            element: (
              <RequireAuth role={_.get(CONFIG.TAB_ROLES, "Activity")}>
                <Activity />
              </RequireAuth>
            ),
            loader: activityLoader,
          },
          {
            path: paths.DASHBOARD_WORKSPACE,
            element: (
              <RequireAuth role={_.get(CONFIG.TAB_ROLES, "Users")}>
                <Workspace mode={TabComponentMode.User} />
              </RequireAuth>
            ),
            loader: workspaceLoader(ApiMode.User),
            action: workspaceAction(ApiMode.Admin),
          },
          {
            path: paths.DASHBOARD_WORKSPACES,
            element: (
              <RequireAuth role={_.get(CONFIG.TAB_ROLES, "Workspaces")}>
                <Workspaces mode={TabComponentMode.User} />
              </RequireAuth>
            ),
            loader: workspacesLoader(ApiMode.User),
            action: workspacesAction,
          },
          {
            path: paths.DASHBOARD_ADMIN,
            element: (
              <RequireAuth role="easi-admin">
                <Admin />
              </RequireAuth>
            ),
            children: _.compact([
              {
                path: paths.DASHBOARD_ADMIN_USER,
                element: <User />,
                action: userAction,
                loader: userLoader,
              },
              {
                path: paths.DASHBOARD_ADMIN_USERS,
                element: <Users />,
                action: usersAction,
                loader: usersLoader,
              },
              {
                path: paths.DASHBOARD_ADMIN_WORKSPACE,
                element: <Workspace mode={TabComponentMode.Admin} />,
                loader: workspaceLoader(ApiMode.Admin),
                action: workspaceAction(ApiMode.Admin),
              },
              {
                path: paths.DASHBOARD_ADMIN_WORKSPACES,
                element: <Workspaces mode={TabComponentMode.Admin} />,
                loader: workspacesLoader(ApiMode.Admin),
                action: workspacesAction,
              },
              {
                path: paths.DASHBOARD_ADMIN_SPAWNER_GROUPS,
                element: <SpawnerGroups />,
                loader: spawnerGroupsLoader,
                action: spawnerGroupsAction,
              },
              {
                element: <Navigate to={paths.DASHBOARD_ADMIN_USERS} replace />,
                index: true,
              },
            ]),
          },
        ]),
      },
      {
        index: true,
        element: <Navigate to={paths.DASHBOARD} replace />,
      },
    ],
  },
];
