import _ from "lodash";

import { fetcher } from "../auth";
import { UserFormSubmissionWithWorkspaces } from "../dashboard/components/user-form";
import { apiUrl, transformKeysDeep } from "./utils";
import { Workspace } from "./workspaces";

export enum WorkspaceUserRole {
  Admin = "admin",
  User = "user",
  Owner = "owner",
}

export enum UserProjectValue {
  ReadOnly = "ro",
  ReadWrite = "rw",
  True = "true",
}

export interface UserProject {
  key: string;
  value: UserProjectValue;
}

export interface AuthProviderUser {
  username: string;
  name: string;
  email: string;
  status:
    | "UNCONFIRMED"
    | "CONFIRMED"
    | "EXTERNAL_PROVIDER"
    | "UNKNOWN"
    | "RESET_REQUIRED"
    | "FORCE_CHANGE_PASSWORD";
  enabled: boolean | undefined;
  created: string;
  modified: string;
}

export interface UsersWorkspace {
  workspace: Workspace;
  role: WorkspaceUserRole;
}

export interface User {
  userId: string;
  username: string;
  name: string;
  groups: string[];
  enabled: boolean | undefined;
  projects: UserProject[];
}

export interface UserWithWorkspaces extends User {
  workspaces: UsersWorkspace[];
}

export interface UsersResponseItem {
  user_id: string;
  groups: string[];
  projects: { [key: string]: UserProjectValue };
  provided?: AuthProviderUser;
}

export interface ApiUserFields {
  user_id: string;
  groups: string[];
  projects: { [key: string]: UserProjectValue };
  enabled: boolean;
}

interface RequestAllArguments {
  signal: AbortSignal;
}
interface RequestArguments {
  signal: AbortSignal;
  userId: string;
}

export const convertToApiUserFields = (
  user: Partial<User>
): Partial<ApiUserFields> => {
  const fields: Partial<ApiUserFields> = {
    groups: _.get(user, "groups"),
    projects: _.isUndefined(user.projects)
      ? undefined
      : _.zipObject(_.map(user.projects, "key"), _.map(user.projects, "value")),
    enabled: _.get(user, "enabled"),
  };
  return fields;
};

export const Users = {
  parseFromApi(fetched: UsersResponseItem[]): User[] {
    return _.map(fetched, (user) => ({
      userId: user.user_id,
      name: _.get(user, "provided.name", ""),
      username: _.get(user, "provided.username", ""),
      enabled: _.get(user, "provided.enabled"),
      groups: user.groups,
      projects: _.map(user.projects, (value, key) => ({ key, value })),
    }));
  },
  async fetch({ signal }: RequestAllArguments): Promise<UsersResponseItem[]> {
    const { data } = await fetcher({ url: apiUrl("users/all"), signal });
    return data as UsersResponseItem[];
  },
  async fetchAllAndParse(args: RequestAllArguments): Promise<User[]> {
    const data = await Users.fetch(args);
    return Users.parseFromApi(data);
  },
  async fetchAndParse(args: RequestArguments): Promise<User> {
    // @TODO: Optimise by only fetching the one user.
    const users = await Users.fetchAllAndParse(args);
    const user = _.find(users, { userId: args.userId });
    if (_.isUndefined(user)) {
      throw new Error("User is undefined");
    }
    return user;
  },
  create(values: UserFormSubmissionWithWorkspaces) {
    return fetcher({
      url: apiUrl(`users/${encodeURIComponent(values.userId)}`),
      method: "POST",
      data: {
        groups: values.groups,
        projects: _.zipObject(
          _.map(values.projects, "key"),
          _.map(values.projects, "value")
        ),
        workspaces: transformKeysDeep(_.snakeCase, values.workspaces),
      },
    });
  },
  delete(values: User) {
    return fetcher({
      url: apiUrl(`users/${encodeURIComponent(values.userId)}`),
      method: "DELETE",
    });
  },
  update(values: User) {
    return fetcher({
      url: apiUrl(`users/${encodeURIComponent(values.userId)}`),
      method: "PATCH",
      data: convertToApiUserFields(values),
    });
  },
  async importCsv(request: Request) {
    return fetcher({
      url: apiUrl("users/csv"),
      method: request.method,
      data: await request.formData(),
      headers: {
        "Content-Type": request.headers.get("Content-Type"),
      },
    });
  },
};
