enum paths {
  ACTIVITY_REPORT_PDF = "/activity-report-pdf/",
  DASHBOARD = "/dashboard/",
  DASHBOARD_ACTIVITY = "/dashboard/activity/",
  DASHBOARD_ADMIN = "/dashboard/admin/",
  DASHBOARD_ADMIN_SPAWNER_GROUPS = "/dashboard/admin/spawner-groups/",
  DASHBOARD_ADMIN_USER = "/dashboard/admin/users/:userId/",
  DASHBOARD_ADMIN_USERS = "/dashboard/admin/users/",
  DASHBOARD_ADMIN_WORKSPACE = "/dashboard/admin/workspaces/:workspaceId/",
  DASHBOARD_ADMIN_WORKSPACES = "/dashboard/admin/workspaces/",
  DASHBOARD_WORKSPACE = "/dashboard/workspaces/:workspaceId/",
  DASHBOARD_WORKSPACES = "/dashboard/workspaces/",
  FAQ = "/resources/faq/",
  SETTINGS = "/settings/",
  SIGN_IN = "/sign-in/",
  SIGN_UP = "/sign-up/",
  SUBSCRIPTION_ONBOARDING_FORM = "/subscription-onboarding-form/",
  SUBSCRIPTION_PAYMENT = "/subscription-payment/",
  TOKENS = "/tokens/",
}

export default paths;
