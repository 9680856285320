import {
  faCaretDown,
  faCaretUp,
  faEllipsisVertical,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ErrorMessage,
  Field,
  FieldArray,
  Form,
  Formik,
  useField,
} from "formik";
import _ from "lodash";
import {
  Alert,
  Button,
  Col,
  Dropdown,
  Form as BsForm,
  FormControlProps,
  Modal,
  ModalDialogProps,
  ModalProps,
  Row,
  Stack,
} from "react-bootstrap";
import * as Yup from "yup";

import { SpawnerGroup } from "../../../api";
import { FormItem } from "../../../common/components/form-item";
import { CustomDropdownToggleButton } from "../custom-dropdown-toggle-button";

interface SpawnerGroupModalProps extends ModalProps {
  onSubmit: (values: SpawnerGroup) => void;
  isSubmitting?: boolean;
  resourceOptionIds: string[];
  isSpawnerGroupIdReadOnly?: boolean;
  spawnerGroup?: SpawnerGroup;
  title?: string;
}

const spawnerGroupSchema = Yup.object({
  spawnerGroupId: Yup.string().required("Spawner group ID is required"),
  profileOptions: Yup.array(
    Yup.object({
      name: Yup.string().required("Name is required"),
      description: Yup.string().required("Description is required"),
      tags: Yup.array(Yup.string().required()).required(),
      imageTagRegex: Yup.string(),
      image: Yup.string().required("Image is required"),
      daskImage: Yup.string().required("Image is required"),
      settings: Yup.array(
        Yup.object({
          key: Yup.string().required("Key is required"),
          value: Yup.string().required("Value is required"),
        }).required()
      ).required(),
      versions: Yup.array(
        Yup.object({
          tag: Yup.string().required("Tag is required"),
          label: Yup.string().required("Label is required"),
        }).required()
      ).required(),
    }).required()
  )
    .test({
      name: "unique-name",
      message: "Names must be unique. Duplicate found: ${name}",
      test: function (value, ctx) {
        if (_.isUndefined(value)) {
          return true;
        }
        const errors: Yup.ValidationError[] = [];
        const names = new Set();
        _.each(value, ({ name }, index) => {
          if (names.has(name)) {
            errors.push(
              ctx.createError({
                path: `${ctx.path}[${index}].name`,
                message: `Name '${name}' is already used.`,
                params: { name },
              })
            );
          }
          names.add(name);
        });
        return _.isEmpty(errors) || _.first(errors);
      },
    })
    .required("Profile options is required"),
  resourceOptions: Yup.array(
    Yup.object({
      resourceOptionId: Yup.string().required("Resource option ID is required"),
      presetLabel: Yup.string(),
    })
  ).required("Resource options is required"),
});

const KeyValueArrayField = ({
  name,
  label,
  theKey,
  value,
}: {
  name: string;
  label: string;
  theKey: { name: string; label: string };
  value: { name: string; label: string };
}) => {
  const meta = useField(name)[1];
  return (
    <div className="pb-3">
      <label className="form-label">{label}</label>
      <div className="border p-3">
        <FieldArray name={name}>
          {({ remove, push }) => (
            <div>
              {/* eslint-disable-next-line */}
              {meta.value.map((_item: any, index: number) => (
                <div className="mb-3" key={index}>
                  <Stack direction="horizontal" gap={3}>
                    <>
                      <label htmlFor={`${name}.${index}.${theKey.name}`}>
                        {theKey.label}
                      </label>
                      <Field
                        name={`${name}.${index}.${theKey.name}`}
                        placeholder={theKey.label}
                        type="text"
                        className="form-control form-control-sm py-0"
                      />
                    </>
                    <>
                      <label htmlFor={`${name}.${index}.${value.name}`}>
                        {value.label}
                      </label>
                      <Field
                        name={`${name}.${index}.${value.name}`}
                        placeholder={value.label}
                        type="text"
                        className="form-control form-control-sm py-0"
                      />
                    </>
                    <div className="ms-auto">
                      <button
                        type="button"
                        className="btn btn-outline-danger btn-sm"
                        onClick={() => remove(index)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  </Stack>
                  <ErrorMessage
                    name={`${name}.${index}.${theKey.name}`}
                    component="div"
                    className="d-block invalid-feedback"
                  />
                  <ErrorMessage
                    name={`${name}.${index}.${value.name}`}
                    component="div"
                    className="d-block invalid-feedback"
                  />
                </div>
              ))}
              <button
                type="button"
                className="btn btn-outline-primary btn-sm"
                onClick={() => push({ [theKey.name]: "", [value.name]: "" })}
              >
                Add
              </button>
            </div>
          )}
        </FieldArray>
      </div>
    </div>
  );
};

const TagsFormItem = ({ name }: { name: string }) => {
  const meta = useField(name)[1];
  return (
    <div className="pb-3">
      <label className="form-label">Features</label>
      <div className="border p-3">
        <FieldArray name={name}>
          {({ remove, push }) => (
            <div>
              {/* eslint-disable-next-line */}
              {meta.value.map((_item: any, index: number) => (
                <div className="mb-3" key={index}>
                  <Stack direction="horizontal" gap={3}>
                    <>
                      <label
                        className="visually-hidden"
                        htmlFor={`${name}.${index}`}
                      >
                        Tag
                      </label>
                      <Field
                        name={`${name}.${index}`}
                        placeholder="Feature"
                        type="text"
                        className="form-control form-control-sm py-0"
                      />
                    </>
                    <div className="ms-auto">
                      <button
                        type="button"
                        className="btn btn-outline-danger btn-sm"
                        onClick={() => remove(index)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  </Stack>
                  <ErrorMessage
                    name={`${name}.${index}`}
                    component="div"
                    className="d-block invalid-feedback"
                  />
                </div>
              ))}
              <button
                type="button"
                className="btn btn-outline-primary btn-sm"
                onClick={() => push("")}
              >
                Add
              </button>
            </div>
          )}
        </FieldArray>
      </div>
    </div>
  );
};

const ProfileOptions = () => {
  const name = "profileOptions";
  const meta = useField(name)[1];
  return (
    <div className="pb-3">
      <FieldArray name={name}>
        {({ remove, push, swap }) => (
          <div>
            {/* eslint-disable-next-line */}
            {meta.value.map((_item: any, index: number) => (
              <div className="border border-dark p-3 mb-3" key={index}>
                <Stack
                  direction="horizontal"
                  className="align-items-start"
                  gap={3}
                >
                  <Row className="w-100">
                    <Col lg="12">
                      <FormItem
                        className="mb-3"
                        name={`${name}.${index}.name`}
                        label="Name"
                        type="text"
                        placeholder="Profile name"
                        as={BsForm.Control}
                      />
                    </Col>
                    <Col lg="8">
                      <FormItem
                        className="mb-3"
                        name={`${name}.${index}.description`}
                        label="Description"
                        type="text"
                        placeholder="Profile option description"
                        rows={4}
                        as={(props: FormControlProps) => {
                          return <BsForm.Control as="textarea" {...props} />;
                        }}
                      />
                    </Col>
                    <Col lg="4">
                      <TagsFormItem name={`${name}.${index}.tags`} />
                    </Col>
                    <Col lg="12">
                      <FormItem
                        className="mb-3"
                        name={`${name}.${index}.image`}
                        label="Image"
                        type="text"
                        placeholder="Image without tag"
                        as={BsForm.Control}
                      />
                    </Col>
                    <Col lg="12">
                      <FormItem
                        className="mb-3"
                        name={`${name}.${index}.daskImage`}
                        label="Dask image"
                        type="text"
                        placeholder="Dask image without tag"
                        as={BsForm.Control}
                      />
                    </Col>
                    <Col lg="12">
                      <KeyValueArrayField
                        label="Kube spawner overrides"
                        name={`${name}.${index}.settings`}
                        theKey={{ name: "key", label: "Key" }}
                        value={{ name: "value", label: "Value" }}
                      />
                    </Col>
                    <Col lg="12">
                      <Alert variant="info">
                        Versions include manually added entries and
                        automatically discovered image tags, with the discovered
                        tags filtered by the regular expression entered below.
                      </Alert>
                    </Col>
                    <Col lg="12">
                      <KeyValueArrayField
                        label="Image versions"
                        name={`${name}.${index}.versions`}
                        theKey={{ name: "tag", label: "Tag" }}
                        value={{ name: "label", label: "Label" }}
                      />
                    </Col>
                    <Col lg="12">
                      <FormItem
                        className="mb-3"
                        name={`${name}.${index}.imageTagRegex`}
                        label="Image tag regex"
                        type="text"
                        placeholder="Enter regex to filter image tags"
                        as={BsForm.Control}
                      />
                    </Col>
                  </Row>
                  <div className="ms-auto">
                    <Dropdown align="end">
                      <Dropdown.Toggle
                        as={CustomDropdownToggleButton}
                        className="btn btn-outline-dark px-3"
                      >
                        <FontAwesomeIcon icon={faEllipsisVertical} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {index > 0 && (
                          <Dropdown.Item onClick={() => swap(index - 1, index)}>
                            <FontAwesomeIcon
                              icon={faCaretUp}
                              className="me-2"
                            />
                            Move up
                          </Dropdown.Item>
                        )}
                        {index < meta.value.length - 1 && (
                          <Dropdown.Item onClick={() => swap(index, index + 1)}>
                            <FontAwesomeIcon
                              icon={faCaretDown}
                              className="me-2"
                            />
                            Move down
                          </Dropdown.Item>
                        )}

                        <Dropdown.Item onClick={() => remove(index)}>
                          <FontAwesomeIcon icon={faTrash} className="me-2" />
                          Remove
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Stack>
              </div>
            ))}
            <button
              type="button"
              className="btn btn-outline-primary btn-sm"
              onClick={() =>
                push({
                  name: "",
                  description: "",
                  image: "",
                  imageTagRegex: "^\\d+\\.\\d+\\.\\d+$",
                  daskImage: "",
                  tags: [],
                  settings: [],
                  versions: [],
                })
              }
            >
              Add profile option
            </button>
          </div>
        )}
      </FieldArray>
    </div>
  );
};

const ResourceOptions = ({
  resourceOptionIds,
}: {
  resourceOptionIds: string[];
}) => {
  const name = "resourceOptions";
  const meta = useField(name)[1];
  return (
    <div className="pb-3">
      <div className="border p-3">
        <FieldArray name={name}>
          {({ remove, push }) => (
            <div>
              {/* eslint-disable-next-line */}
              {meta.value.map((_item: any, index: number) => (
                <div className="mb-3" key={index}>
                  <Stack direction="horizontal" gap={3}>
                    <>
                      <label htmlFor={`${name}.${index}.resourceOptionId`}>
                        ID
                      </label>
                      <Field
                        name={`${name}.${index}.resourceOptionId`}
                        as="select"
                        className="form-control form-control-sm py-0"
                      >
                        <option value="">Select</option>
                        {_.map(resourceOptionIds, (id) => (
                          <option key={id} value={id}>
                            {id}
                          </option>
                        ))}
                      </Field>
                    </>
                    <>
                      <label htmlFor={`${name}.${index}.presetLabel`}>
                        Preset
                      </label>
                      <Field
                        name={`${name}.${index}.presetLabel`}
                        placeholder="Optional preset Label"
                        type="text"
                        className="form-control form-control-sm py-0"
                      />
                    </>
                    <div className="ms-auto">
                      <button
                        type="button"
                        className="btn btn-outline-danger btn-sm"
                        onClick={() => remove(index)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  </Stack>
                  <ErrorMessage
                    name={`${name}.${index}.resourceOptionId`}
                    component="div"
                    className="d-block invalid-feedback"
                  />
                  <ErrorMessage
                    name={`${name}.${index}.presetLabel`}
                    component="div"
                    className="d-block invalid-feedback"
                  />
                </div>
              ))}
              <button
                type="button"
                className="btn btn-outline-primary btn-sm"
                onClick={() => push({ resourceOptionId: "", presetLabel: "" })}
              >
                Add
              </button>
            </div>
          )}
        </FieldArray>
      </div>
    </div>
  );
};

const Dialog = ({ children }: ModalDialogProps) => children;

export const SpawnerGroupModal = ({
  onSubmit,
  title,
  show,
  spawnerGroup = {
    spawnerGroupId: "",
    profileOptions: [],
    resourceOptions: [],
  },
  isSubmitting = false,
  resourceOptionIds,
  isSpawnerGroupIdReadOnly = false,
  ...modalProps
}: SpawnerGroupModalProps) => {
  return (
    <Modal show={show} size="xl" {...modalProps} dialogAs={Dialog}>
      <Formik
        validationSchema={spawnerGroupSchema}
        onSubmit={onSubmit}
        initialValues={spawnerGroup}
      >
        <Form method="post" noValidate className="h-100">
          <Modal.Dialog scrollable size="xl">
            <Modal.Header closeButton>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <fieldset disabled={isSubmitting || !show}>
                <Row>
                  <Col lg="4">
                    <FormItem
                      className="mb-3"
                      name="spawnerGroupId"
                      label="ID"
                      type="text"
                      placeholder="Spawner group ID"
                      as={BsForm.Control}
                      readOnly={isSpawnerGroupIdReadOnly}
                    />
                  </Col>
                  <Col lg="12">
                    <h4 className="border-top pt-3 mt-3">Profile options</h4>
                    <ProfileOptions />
                  </Col>
                  <Col lg="12">
                    <h4 className="border-top pt-3 mt-3">Resource options</h4>
                    <ResourceOptions resourceOptionIds={resourceOptionIds} />
                  </Col>
                </Row>
              </fieldset>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting || !show}
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </Form>
      </Formik>
    </Modal>
  );
};
